import React, { ReactNode } from 'react';

import { ButtonComp, GoldenButton } from './ButtonStyles';

export interface ButtonThemeI {
  bg: string;
  border: string;
  text: string;
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  width?: string;
  height?: string;
  buttonType?: 'solid' | 'outlined' | 'open';
  buttonTheme?: 'primary' | 'grey' | 'black' | 'lightGrey' | 'golden' | 'white';
  customTheme?: ButtonThemeI;
  disabled?: boolean;
  ariaLabel?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  width,
  height,
  buttonType = 'solid',
  buttonTheme = 'primary',
  customTheme,
  disabled,
  style,
  ariaLabel,
  ...props
}) => {
  return (
    <>
      {buttonTheme === 'golden' ? (
        <GoldenButton
          style={style}
          disabled={disabled}
          width={width}
          height={height}
          buttonTheme={buttonTheme}
          buttonType={buttonType}
          customTheme={customTheme}
          aria-label={ariaLabel || 'button'}
          {...props}
        >
          {children}
        </GoldenButton>
      ) : (
        <ButtonComp
          style={style}
          disabled={disabled}
          width={width}
          height={height}
          buttonTheme={buttonTheme}
          buttonType={buttonType}
          customTheme={customTheme}
          aria-label={ariaLabel || 'button'}
          {...props}
        >
          {children}
        </ButtonComp>
      )}
    </>
  );
};

export default Button;
