import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const BrandLogoImage = styled.div<{ isSubscribed?: boolean }>`
  height: 30px;
  cursor: pointer;
  min-width: ${(props) => (props.isSubscribed ? '172px' : '122px')};
  width: ${(props) => (props.isSubscribed ? '172px' : '122px')};
  position: relative;
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 24px;
    min-width: ${(props) => (props.isSubscribed ? '136px' : '96px')};
    width: ${(props) => (props.isSubscribed ? '136px' : '96px')};
  }
`;
