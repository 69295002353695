import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../constants/colors';
import { ButtonThemeI } from './Button';

interface ButtonCompProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  width?: string;
  height?: string;
  buttonType?: 'solid' | 'outlined' | 'open';
  buttonTheme?: 'primary' | 'grey' | 'black' | 'lightGrey' | 'golden' | 'white';
  customTheme?: ButtonThemeI;
  disabled?: boolean;
}

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 25%;
  }
  50% {
    background-position: 50% 25%;
  }
  100%{
     background-position: 100% 50%;
  }
  

`;

export const GoldenButton = styled.button<ButtonCompProps>`
  display: flex;
  align-items: center;
  place-content: center;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1rem;
  font-family: Onest-Bold;

  // background: linear-gradient(
  //   135deg,
  //   #cfa80b,
  //   #c67a05,
  //   #cfa80b,
  //   #c67a05,
  //   #cfa80b,
  //   #c67a05,
  //   #cfa80b
  // );

  background: linear-gradient(
    97.73deg,
    #cfa80b 3.19%,
    #c67a05 88.09%,
    #cfa80b 172.93%,
    #c67a05 257.86%,
    #cfa80b 342.66%
  );
  border: ${(props) =>
    props.buttonType === 'outlined'
      ? props.buttonTheme === 'primary'
        ? `1px solid ${COLORS.primary}`
        : `1px solid ${COLORS.black}`
      : 'none'};

  // background-size: 400% 400%;
  // animation: ${gradientAnimation} 6s linear infinite;
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1rem;
  font-family: Onest-Bold;
  padding: 16px;
  transition: 0.3s;
  gap: 8px;
  color: ${(props) =>
    props.buttonType === 'open' || props.buttonType === 'outlined'
      ? props.buttonTheme === 'primary'
        ? props.theme.palette.text.primary
        : COLORS.black
      : props.buttonTheme === 'grey'
      ? props.theme.palette.text.t1
      : props.buttonTheme === 'lightGrey'
      ? props.theme.palette.text.t1
      : COLORS.white};
  opacity: ${(props) => (props.disabled ? '0.3' : '1')};
  ${(props) => (props.width ? `width: ${props.width};` : '')}
  ${(props) => (props.height ? `height: ${props.height};` : '')}
  ${(props) => (props.disabled ? `opacity: 0.5;` : '')}

  & p,
  span,
  div {
    font-family: Onest-Bold;
  }
`;


export const ButtonComp = styled.button<ButtonCompProps>`
  display: flex;
  align-items: center;
  place-content: center;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1rem;
  font-family: Onest-Bold;
  border: ${(props) =>
    props.buttonType === 'outlined'
      ? props.buttonTheme === 'primary'
        ? `1px solid ${COLORS.primary}`
        : `1px solid ${COLORS.black}`
      : 'none'};
  background: ${(props) =>
    props.buttonType === 'open' || props.buttonType === 'outlined'
      ? 'transparent'
      : props.buttonTheme === 'primary'
      ? props.theme.palette.button.primary.main
      : props.buttonTheme === 'grey'
      ? props.theme.palette.button.grey.main
      : props.buttonTheme === 'lightGrey'
      ? props.theme.palette.button.lightGrey.main
      : props.buttonTheme === 'white'
      ? COLORS.white
      : COLORS.black};
  color: ${(props) =>
    props.buttonType === 'open' || props.buttonType === 'outlined'
      ? props.buttonTheme === 'primary'
        ? props.theme.palette.text.primary
        : COLORS.black
      : props.buttonTheme === 'grey'
      ? props.theme.palette.text.t1
      : props.buttonTheme === 'lightGrey'
      ? props.theme.palette.text.t1
      : props.buttonTheme === 'white'
      ? COLORS.black
      : COLORS.white};
  padding: 16px;
  // transition: 0.3s;
  gap: 8px;
  opacity: ${(props) => (props.disabled ? '0.3' : '1')};
  ${(props) => (props.width ? `width: ${props.width};` : '')}
  ${(props) => (props.height ? `height: ${props.height};` : '')}
  ${(props) => (props.disabled ? `opacity: 0.5;` : '')}
  &:hover {
    ${(props) =>
      props.disabled
        ? ''
        : props.buttonType === 'open' || props.buttonType === 'outlined'
        ? ''
        : `background: ${
            props.buttonTheme === 'primary'
              ? props.theme.palette.button.primary.hover
              : props.buttonTheme === 'grey'
              ? props.theme.palette.button.grey.hover
              : props.buttonTheme === 'lightGrey'
              ? props.theme.palette.button.lightGrey.hover
              : props.buttonTheme === 'white'
              ? COLORS.white
              : COLORS.blackLight2
          };`}
  }
  &:active {
    ${(props) => (props.disabled ? '' : `transform: scale(0.95);`)}
  }
  & p,
  span,
  div {
    font-family: Onest-Bold;
  }
`;
