import dynamic from 'next/dynamic';
import { memo, useEffect, useRef, useState } from 'react';
import {
  FooterContainer,
  FooterHeader,
  FooterItem,
  FooterItemTitle,
  FooterLanguageContainer,
  FooterLanguageTitle,
  FooterRatingContainer,
  FooterWrapper,
  NewsletterStrip,
} from './FooterStyle';

const Link = dynamic(() => import('next/link'), {
  ssr: true,
});

import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';

import { languageList } from '../../constants/languageList';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useLocale } from '../../contexts/LocalizationContext';
import useVisibility from '../../hooks/useVisibility';
import data from '../../package.json';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  closeTrustpilotWidget,
  openRlcSelectorRedux,
  openTrustpilotWidget,
} from '../../redux/modalSlice';
import { saveEventV3 } from '../../utils/eventTracking';
import {
  triggerGtmFooterLinkClick,
  triggerGtmViewFooter,
} from '../../utils/gtm';
import { getLanguageFromLocale } from '../../utils/localizationUtils';
import { WE_USER_EVENTS_FOOTER_LINK_CLICKED } from '../../utils/we';
import CCFlag from '../CCFlag/CCFlag';
import Button from '../NewButton/Button';
import FooterBottomStrip from './FooterBottomStrip';

const Newsletter = dynamic(
  () => import('../Newsletter/Newsletter').then((module) => module.Newsletter),
  {
    ssr: false,
  }
);

const FooterPaymentIconDetails = dynamic(
  () => import('./FooterPaymentIconDetails'),
  {
    ssr: false,
  }
);

const FooterTrustpilotBox = dynamic(() => import('./FooterTrustpilotBox'), {
  ssr: false,
});

const cookies = new Cookies();

const Footer = () => {
  const footerRef = useRef<HTMLDivElement>(null);

  const [version, setVersion] = useState<string>('');
  const [footerViewed, setFooterViewed] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { locationDetailsFetched } = useAppSelector((state) => state.global);
  const { locationDetails } = useAppSelector((state) => state.global);

  const router = useRouter();
  const { locale } = router;
  const [isVisible, visibilityRef] = useVisibility<HTMLDivElement>();
  const { cleverTap } = useAnalytics();

  const { messages } = useLocale();
  const {
    about_msg,
    sell_on_driffle_msg,
    faqs_msg,
    create_a_ticket_msg,
    contact_us_msg,
    business_msg,
    become_affiliate_msg,
    buy_msg,
    product_list_msg,
    language_region_msg,
    company_msg,
    activation_guide_msg,
    help_msg,
    community_msg,
    blog_msg,
  } = messages || {};

  function observerCallback(entries: any) {
    if (entries) {
      const [entry] = entries;
      if (entry.isIntersecting && !footerViewed) {
        triggerGtmViewFooter();
        setFooterViewed(true);
      }
    }
  }

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
      let y = data.version;
      if (y) setVersion(y || '-');
    }
    let observer: IntersectionObserver | null = null;
    if ('IntersectionObserver' in window) {
      observer = new IntersectionObserver(observerCallback, options);
      if (footerRef.current) observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current && observer) observer.unobserve(footerRef.current);
    };
  }, []);

  useEffect(() => {
    const trustpilotWidgetStatus = cookies.get('trustpilot-widget') === '1';
    if (isVisible) {
      dispatch(closeTrustpilotWidget());
    } else {
      if (!trustpilotWidgetStatus) {
        dispatch(openTrustpilotWidget());
      } else {
        dispatch(closeTrustpilotWidget());
      }
    }
  }, [isVisible]);

  return (
    <FooterWrapper ref={footerRef}>
      {/* <FooterCatalog /> */}
      <div ref={visibilityRef} />
      <FooterPaymentIconDetails isVisible={isVisible} />
      <FooterContainer>
        <div>
          <FooterHeader>{company_msg}</FooterHeader>
          <FooterItem>
            <FooterItemTitle>
              <Link href="/about" passHref prefetch={false}>
                <a
                  onClick={() => {
                    saveEventV3({
                      category: 'footer',
                      action: 'click',
                      label: 'about',
                      properties: '',
                      value: ['/about'],
                      from: router,
                    });
                    triggerGtmFooterLinkClick({
                      label: 'about',
                      link: '/about',
                    });
                    WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                      {
                        footerOptionName: 'About',
                        link: '/about',
                        pageUrl: window.location.href,
                      },
                      cleverTap
                    );
                  }}
                >
                  {about_msg}
                </a>
              </Link>
            </FooterItemTitle>
          </FooterItem>

          <FooterItem>
            <FooterItemTitle>
              <Link href="/contact" passHref prefetch={false}>
                <a
                  onClick={() => {
                    saveEventV3({
                      category: 'footer',
                      action: 'click',
                      label: 'contact us',
                      properties: '',
                      value: ['/contact'],
                      from: router,
                    });
                    triggerGtmFooterLinkClick({
                      label: 'contact us',
                      link: '/contact',
                    });
                    WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                      {
                        footerOptionName: 'Contact Us',
                        link: '/contact',
                        pageUrl: window.location.href,
                      },
                      cleverTap
                    );
                  }}
                >
                  {contact_us_msg}
                </a>
              </Link>
            </FooterItemTitle>
          </FooterItem>

          {/* <FooterItem>
            <FooterItemTitle>
              <Link href="/careers" passHref prefetch={false}>
                <a
                  onClick={() => {
                    saveEventV3({
                      category: 'footer',
                      action: 'click',
                      label: 'careers',
                      properties: '',
                      value: ['/careers'],
                      from: router,
                    });
                    WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                      {
                        footerOptionName: 'Careers',
                        link: '/contact',
                        pageUrl: window.location.href,
                      },
                      cleverTap
                    );
                  }}
                >
                  {careers_msg}
                </a>
              </Link>
            </FooterItemTitle>
          </FooterItem> */}
        </div>
        <div>
          <FooterHeader>{buy_msg}</FooterHeader>
          <FooterItem>
            <FooterItemTitle>
              <Link href="/collection-list" passHref prefetch={false}>
                <a
                  onClick={() => {
                    saveEventV3({
                      category: 'footer',
                      action: 'click',
                      label: 'Collections',
                      properties: '',
                      value: ['/collection-list'],
                      from: router,
                    });
                    triggerGtmFooterLinkClick({
                      label: 'Collections',
                      link: '/collection-list',
                    });
                    WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                      {
                        footerOptionName: 'Collections',
                        link: '/collection-list',
                        pageUrl: window.location.href,
                      },
                      cleverTap
                    );
                  }}
                >
                  Collections
                </a>
              </Link>
            </FooterItemTitle>
          </FooterItem>

          <FooterItem>
            <FooterItemTitle>
              <Link
                href="/product-list/games-and-more-starting-with-A"
                passHref
                prefetch={false}
              >
                <a
                  onClick={() => {
                    saveEventV3({
                      category: 'footer',
                      action: 'click',
                      label: 'Product list',
                      properties: '',
                      value: ['/product-list/games-and-more-starting-with-A'],
                      from: router,
                    });
                    triggerGtmFooterLinkClick({
                      label: 'Product list',
                      link: '/product-list/games-and-more-starting-with-A',
                    });

                    WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                      {
                        footerOptionName: 'Product list',
                        link: '/product-list/games-and-more-starting-with-A',
                        pageUrl: window.location.href,
                      },
                      cleverTap
                    );
                  }}
                >
                  {product_list_msg}
                </a>
              </Link>
            </FooterItemTitle>
          </FooterItem>
        </div>
        <div>
          <FooterHeader>{help_msg}</FooterHeader>
          <FooterItem>
            <FooterItemTitle>
              <Link href="/faq" passHref prefetch={false}>
                <a
                  onClick={() => {
                    saveEventV3({
                      category: 'footer',
                      action: 'click',
                      label: 'FAQs',
                      properties: '',
                      value: ['/faq'],
                      from: router,
                    });
                    triggerGtmFooterLinkClick({
                      label: 'FAQs',
                      link: '/faq',
                    });
                    WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                      {
                        footerOptionName: 'FAQ',
                        link: '/faq',
                        pageUrl: window.location.href,
                      },
                      cleverTap
                    );
                  }}
                >
                  {faqs_msg}
                </a>
              </Link>
            </FooterItemTitle>
          </FooterItem>

          <FooterItem>
            <FooterItemTitle>
              <Link
                href="https://driffle.com/blog/category/activation-guides/"
                passHref
                prefetch={false}
              >
                <a
                  onClick={() => {
                    saveEventV3({
                      category: 'footer',
                      action: 'click',
                      label: 'How to activate game',
                      properties: '',
                      value: [
                        'https://driffle.com/blog/category/activation-guides/',
                      ],
                      from: router,
                    });
                    triggerGtmFooterLinkClick({
                      label: 'How to activate game',
                      link: 'https://driffle.com/blog/category/activation-guides/',
                    });
                    WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                      {
                        footerOptionName: 'How to activate game',
                        link: 'https://driffle.com/blog/category/activation-guides/',
                        pageUrl: window.location.href,
                      },
                      cleverTap
                    );
                  }}
                >
                  {activation_guide_msg ?? 'Activation Guides'}
                </a>
              </Link>
            </FooterItemTitle>
          </FooterItem>
          <FooterItem>
            <FooterItemTitle>
              <Link href="/support" passHref prefetch={false}>
                <a
                  onClick={() => {
                    saveEventV3({
                      category: 'footer',
                      action: 'click',
                      label: 'Create a ticket',
                      properties: '',
                      value: ['/support'],
                      from: router,
                    });
                    triggerGtmFooterLinkClick({
                      label: 'Create a ticket',
                      link: '/support',
                    });
                    WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                      {
                        footerOptionName: 'Create a ticket',
                        link: '/support',
                        pageUrl: window.location.href,
                      },
                      cleverTap
                    );
                  }}
                >
                  {create_a_ticket_msg}
                </a>
              </Link>
            </FooterItemTitle>
          </FooterItem>
        </div>

        <div>
          <FooterHeader>{community_msg}</FooterHeader>

          <FooterItem>
            <FooterItemTitle>
              <Link href="https://driffle.com/blog" passHref prefetch={false}>
                <a
                  target={'_blank'}
                  rel="noopener noreferrer"
                  onClick={() => {
                    saveEventV3({
                      category: 'footer',
                      action: 'click',
                      label: 'Blog',
                      properties: '',
                      value: ['https://driffle.com/blog'],
                      from: router,
                    });
                    triggerGtmFooterLinkClick({
                      label: 'Blog',
                      link: 'https://driffle.com/blog',
                    });
                    WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                      {
                        footerOptionName: 'Blog',
                        link: 'https://driffle.com/blog',
                        pageUrl: window.location.href,
                      },
                      cleverTap
                    );
                  }}
                >
                  {blog_msg}
                </a>
              </Link>
            </FooterItemTitle>
          </FooterItem>
          <FooterItem>
            <FooterItemTitle>
              <Link href="/become-affiliate" passHref prefetch={false}>
                <a
                  target={'_blank'}
                  rel="noopener noreferrer"
                  onClick={() => {
                    saveEventV3({
                      category: 'footer',
                      action: 'click',
                      label: 'Affiliate',
                      properties: '',
                      value: ['become-affiliate'],
                      from: router,
                    });
                    triggerGtmFooterLinkClick({
                      label: 'Affiliate',
                      link: '/become-affiliate',
                    });
                    WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                      {
                        footerOptionName: 'affiliate',
                        link: 'become-affiliate',
                        pageUrl: window.location.href,
                      },
                      cleverTap
                    );
                  }}
                >
                  {become_affiliate_msg}
                </a>
              </Link>
            </FooterItemTitle>
          </FooterItem>
        </div>

        <div>
          <FooterHeader>{business_msg}</FooterHeader>

          <FooterItem>
            <FooterItemTitle>
              <Link href="/sell-on-driffle" passHref prefetch={false}>
                <a
                  onClick={() => {
                    triggerGtmFooterLinkClick({
                      label: 'Sell on driffle',
                      link: '/sell-on-driffle',
                    });
                    saveEventV3({
                      category: 'footer',
                      action: 'click',
                      label: 'Sell on driffle',
                      properties: '',
                      value: ['/sell-on-driffle'],
                      from: router,
                    });
                    WE_USER_EVENTS_FOOTER_LINK_CLICKED(
                      {
                        footerOptionName: 'Sell on Driffle',
                        link: '/sell-on-driffle',
                        pageUrl: window.location.href,
                      },
                      cleverTap
                    );
                  }}
                >
                  {sell_on_driffle_msg}
                </a>
              </Link>
            </FooterItemTitle>
          </FooterItem>
        </div>

        <FooterRatingContainer>
          <FooterItem>
            <FooterTrustpilotBox />
            {/* {footerViewed ? <TrustPilot mode="dark" /> : <TrustPilotSkeleton />} */}
          </FooterItem>
        </FooterRatingContainer>
      </FooterContainer>
      <NewsletterStrip>
        <Newsletter />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {locationDetailsFetched && (
            <FooterLanguageContainer>
              <FooterLanguageTitle>{language_region_msg}</FooterLanguageTitle>
              <Button
                buttonTheme="grey"
                onClick={() => {
                  saveEventV3({
                    category: 'footer',
                    action: 'click',
                    label: 'rlc-button',
                    properties: '',
                    value: [],
                    from: router,
                  });
                  dispatch(openRlcSelectorRedux());
                }}
                // width="160px"
                height="48px"
                aria-label={`${getLanguageFromLocale(locale)} - ${
                  locationDetails?.currency
                }`}
                style={{ background: '#353535', color: '#fff' }}
              >
                <CCFlag
                  countryCode={
                    locationDetails?.flag_country_code ||
                    locationDetails?.country_code
                  }
                  header={true}
                />
                {locationDetails?.currency} &#x2022;{' '}
                {/* {getLanguageFromLocale(locale)} */}
                {languageList?.find((cur) => cur.locale === locale)?.name}
              </Button>
            </FooterLanguageContainer>
          )}
        </div>
      </NewsletterStrip>

      {/* {locationDetailsFetched && (
        <FooterLanguageContainer>
          <FooterLanguageTitle>{language_region_msg}</FooterLanguageTitle>
          <Button
            buttonTheme="grey"
            onClick={() => {
              saveEventV3({
                category: 'footer',
                action: 'click',
                label: 'rlc-button',
                properties: '',
                value: [],
                from: router,
              });
              dispatch(openRlcSelectorRedux());
            }}
            width="160px"
            height="48px"
            aria-label={`${getLanguageFromLocale(locale)} - ${
              locationDetails.currency
            }`}
          >
            {getLanguageFromLocale(locale)} &#x2022; {locationDetails.currency}
          </Button>
        </FooterLanguageContainer>
      )} */}

      <FooterBottomStrip />
    </FooterWrapper>
  );
};

export default memo(Footer);
