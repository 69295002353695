import Image from 'next/image';
import { memo } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { BrandLogoImage } from './BrandLogoStyle';

const BrandLogo = ({ color }: { color?: string }) => {
  const user = useAppSelector((state) => state.user);
  return (
    <>
      <BrandLogoImage isSubscribed={user.subscription === 'active'}>
        {user.subscription === 'active' ? (
          <Image
            src={`/logo/driffle-plus-dark-logo.svg`}
            alt="Driffle logo"
            priority={true}
            layout="fill"
            objectFit="cover"
            unoptimized
          />
        ) : (
          <Image
            src={`/logo/driffle-logo-${color ?? 'white'}.svg`}
            alt="Driffle logo"
            priority={true}
            layout="fill"
            objectFit="cover"
            unoptimized
          />
        )}
      </BrandLogoImage>
    </>
  );
};

export const DealstoreBrandLogo = () => {
  return (
    <>
      <BrandLogoImage>
        <Image
          src="/logo/dealstore.svg"
          alt="dealstore logo"
          priority={true}
          layout="fill"
          objectFit="cover"
          unoptimized
        />
      </BrandLogoImage>
    </>
  );
};

export const ESimBrandLogo = () => {
  return (
    <>
      <BrandLogoImage style={{ width: '167px', height: '30.55px' }}>
        <Image
          src="/logo/driffle-esims-logo.svg"
          alt="Driffle ESims"
          priority={true}
          layout="fill"
          objectFit="cover"
          unoptimized
        />
      </BrandLogoImage>
    </>
  );
};

export default memo(BrandLogo);
